import axios from 'axios';

import { Query } from './Query';
import { Answer } from './Answer';

export const MaxOptionsToReturn: number = 30;

export function submit(query: Query): Promise<Answer> {
    // we will only pass in the masked question and/or options if the user says we should not autocomplete
    const maskedQuestion: string | undefined = query.maskedQuestion.trim() || undefined;
    const options: string[] | undefined = query.options.length ? query.options : undefined;

    const payload = {
        video: query.video,
        starttime: '0',
        max: MaxOptionsToReturn,
        soundcheck0: query.useSound,
        maskedQuestion,
        options,
    };

    return axios.post<Answer>('/api/submit', payload).then((resp) => {
        return resp.data;
    });
}
