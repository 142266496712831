import React from 'react';

import styled from 'styled-components';
import { belowOrEqualTo, textStyles } from '@allenai/varnish';

import mosaicSrc from './logo-mosaic-color-56px.svg';

export const MosaicFooter = () => {
    return (
        <TeamBackground>
            <TeamContent>
                <a href="https://mosaic.allenai.org/">
                    <LogoMosaic src={mosaicSrc} alt="Mosaic" />
                </a>
                <TeamDesc>
                    This demo page is built and maintained by{' '}
                    <a href="https://mosaic.allenai.org/">Mosaic</a> and colleagues at the{' '}
                    <a href="http://allenai.org">Allen Institute for AI</a>. Our team seeks to
                    advance machine common sense to create AI systems that learn, and reason about
                    the world.
                </TeamDesc>
            </TeamContent>
        </TeamBackground>
    );
};

const TeamBackground = styled.div`
    background: ${({ theme }) => theme.color.N2};
    margin: ${({ theme }) =>
        `${theme.spacing.xl2} -${theme.spacing.md} ${theme.spacing.xl} -${theme.spacing.md}`};
    padding: ${({ theme }) => theme.spacing.md};
`;

const TeamContent = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.md};
    p {
        margin-bottom: ${({ theme }) => theme.spacing.xs};
    }
    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.md)} {
        grid-template-columns: 1fr;
    }
`;

const TeamDesc = styled(textStyles.Small)``;

const LogoMosaic = styled.img`
    height: 54px;
    max-width: 100%;
`;
