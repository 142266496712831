export class Query {
    constructor(
        readonly title: string = '',
        readonly video: string = '',
        readonly maskedQuestion: string = '',
        readonly options: string[] = [],
        readonly useSound: boolean = true
    ) {}

    private static readonly titleURLKey = 't';
    private static readonly videoURLKey = 'v';
    private static readonly questionURLKey = 'q';
    private static readonly maskedQuestionURLKey = 'mq';
    private static readonly autoComputeMaskedQuestionURLKey = 'amq';
    private static readonly autoComputeOptionsURLKey = 'ao';
    private static readonly optionsURLKey = 'o';
    private static readonly useSoundURLKey = 's';

    isValid() {
        return !!(this.title && this.video && this.maskedQuestion && this.options.length);
    }

    toQueryString(): string {
        const params = new URLSearchParams();
        params.set(Query.titleURLKey, this.title);
        params.set(Query.videoURLKey, this.video);
        params.set(Query.maskedQuestionURLKey, this.maskedQuestion);
        params.set(Query.useSoundURLKey, this.useSound.toString());
        // we have to append each selection value in the options array to the URL
        for (const selection of this.options) {
            params.append(Query.optionsURLKey, selection);
        }
        return params.toString();
    }

    public static fromQueryString(searchParams: string): Query {
        const params = new URLSearchParams(searchParams);
        return new Query(
            params.get(Query.titleURLKey) ?? '',
            params.get(Query.videoURLKey) ?? '',
            params.get(Query.maskedQuestionURLKey) ?? '',
            params.getAll(Query.optionsURLKey) ?? [],
            params.get(Query.useSoundURLKey) === 'true' ?? false
        );
    }
}
