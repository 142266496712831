import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Table from 'antd/es/table';
import { BasicFilterDropdown, FilterIcon } from '@allenai/varnish';
import { Output, Spark, SparkEnvelope, SparkValue } from '@allenai/tugboat/components';

import { Answer } from '../api/Answer';

interface Props {
    data: Answer;
    maskStr: string;
    className?: string;
}

export interface ProcessedPrediction {
    key: string;
    word: string;
    score: number;
    result: ReactNode;
}

export const Predictions = ({ data, maskStr, className }: Props) => {
    if (!data || !data.response) {
        return null;
    }
    const doubleSplitToken = '___SPLIT_ON_ME___';
    const tokens = data.response.maskedQuestion
        .split(maskStr)
        .join(`${doubleSplitToken}${maskStr}${doubleSplitToken}`)
        .split(doubleSplitToken);

    // convert a single mask with either a mask number or a prediction
    const getResult = (replacement: string, maskNumber: number) => {
        const ret: React.ReactNodeArray = [];
        let masksSeen = 0;
        tokens.forEach((t, i) => {
            let r = <span key={i}>{`${t}${' '}`}</span>;
            if (t === maskStr) {
                if (masksSeen === maskNumber) {
                    r = <Mask key={i}>{`${replacement}${' '}`}</Mask>;
                } else {
                    r = <i key={i}>{`[MASK${masksSeen + 1}${'] '}`}</i>;
                }
                masksSeen++;
            }
            ret.push(r);
        });
        return ret;
    };

    // convert answer to rows for our table of answers
    const maskList = data.response.answers.map((answers, i) => {
        return {
            key: i,
            maskPrediction: answers.map((w) => {
                return {
                    key: w.value,
                    word: w.value,
                    score: w.score,
                    result: getResult(w.value, i),
                };
            }),
        };
    });

    const percent = new Intl.NumberFormat(navigator.language, {
        style: 'percent',
        maximumFractionDigits: 1,
    });

    const columns = [
        {
            title: 'Answer',
            dataIndex: 'result',
            key: 'result',
            sorter: (a: ProcessedPrediction, b: ProcessedPrediction) => (a.word < b.word ? -1 : 1),
            sortDirections: ['descend', 'ascend'],
            filterDropdown: BasicFilterDropdown,
            filterIcon: FilterIcon,
            onFilter: (filter: any, record: ProcessedPrediction) =>
                record.word.toLowerCase().includes(filter.toString().toLowerCase()),
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            render: (val: number) => (
                <div title={val.toString()}>
                    <SparkEnvelope>
                        <Spark value={100 * val} />
                    </SparkEnvelope>{' '}
                    <SparkValue>{percent.format(val)}</SparkValue>
                </div>
            ),
            sorter: (a: ProcessedPrediction, b: ProcessedPrediction) => a.score - b.score,
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
    ];

    const answerPageSize = maskList.length > 1 ? 3 : 10;
    return (
        <div className={className}>
            <Output>
                <Output.SubSection key="images" title="Video Images Used">
                    <ImageGrid>
                        {data.response.imgs.map((v) => (
                            <Card>
                                <span>{Math.floor(v.seconds)}s</span>
                                <img
                                    alt={'Video Question: ' + data.response.maskedQuestion}
                                    src={`data:image/png;base64,${v.base64Encoded}`}
                                />
                            </Card>
                        ))}
                    </ImageGrid>
                </Output.SubSection>
                {maskList.map((m) => (
                    <Output.SubSection
                        key={m.key}
                        title={`Merlot Reserve's Top Answers${
                            maskList.length > 1 ? ' (Mask ' + (Number(m.key) + 1) + ')' : ''
                        }`}>
                        <AnswerTable
                            dataSource={m.maskPrediction}
                            columns={columns}
                            pagination={
                                m.maskPrediction.length > answerPageSize && {
                                    pageSize: answerPageSize,
                                    simple: true,
                                }
                            }
                        />
                    </Output.SubSection>
                ))}
            </Output>
        </div>
    );
};

const AnswerTable = (p: any) => Table<ProcessedPrediction>(p);

const Mask = styled.span`
    font-weight: ${({ theme }) => theme.typography.font.weight.bold};
    padding: 1px 4px;
    border: ${({ theme }) => theme.color.O7.hex} 1px dashed;
`;

const Card = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    max-width: 176px;
    margin: ${({ theme }) => theme.spacing.md} ${({ theme }) => theme.spacing.sm};
    font-weight: bold;
    gap: ${({ theme }) => theme.spacing.xxs};
`;

const ImageGrid = styled.div`
    display: flex;
    flex-wrap: wrap;

    img {
        width: 100%;
        border-radius: ${({ theme }) => theme.spacing.xs};
        box-shadow: 0px 4px 16px rgb(10 41 57 / 75%);
    }
`;
