import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Alert from 'antd/es/alert';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import Checkbox from 'antd/es/checkbox';
import Form from 'antd/es/form';
import ReactPlayer from 'react-player';
import { textStyles } from '@allenai/varnish';
import {
    StarOutlined,
    ProfileOutlined,
    PicRightOutlined,
    FileTextOutlined,
    GithubOutlined,
} from '@ant-design/icons';

import { submit, MaxOptionsToReturn } from '../api/submit';
import { Answer } from '../api/Answer';
import { Query } from '../api/Query';
import { Predictions } from '../components/Predictions';
import { MosaicFooter } from '../components/MosaicFooter';

// import coffeeSrc from '../images/coffee.jpg';
import cookingSrc from '../images/cooking.png';
// import guitarSrc from '../images/guitar.jpg';
// import iphoneSrc from '../images/iphone.jpg';
// import magnusSrc from '../images/magnus.jpg';
// import seattleSrc from '../images/seattle.jpg';
// import onionSrc from '../images/onion.jpg';
import skiPatrolSrc from '../images/skiPatrol.jpg';
// import theWaitSrc from '../images/theWait.jpg';
import toastSrc from '../images/toast.jpg';
// import bagelsSrc from '../images/bagels.jpg';
// import foleySrc from '../images/foley.jpg';
import dinerSrc from '../images/dinerscene.jpg';
// import onigiriSrc from '../images/onigiri.jpg';
import rollerCoasterSrc from '../images/rollercoaster.jpg';
import pchSrc from '../images/pch.jpg';
import sidewaysSrc from '../images/sideways.jpg';
import idolSrc from '../images/idol.jpg';
// import cowboySrc from '../images/cowboy.jpg';
import voiceSrc from '../images/voice.jpg';
import moonlightSrc from '../images/moonlight.jpg';

type FormValue = Partial<Query>;

interface Example {
    title: string;
    label: string;
    url: string;
    placeholderImg: string;
    question: string;
    maskedQuestion: string;
    options: string[];
    useSound: boolean;
}

const maskStr = `<|MASK|>`;

const examples: Example[] = [
    {
        title: 'Diner Scene',
        label: 'Diner Scene',
        url: 'https://www.youtube.com/watch?v=eAvVe92mi5k',
        placeholderImg: dinerSrc,
        question: `The man is pointing because he wants to ${maskStr}.`,
        maskedQuestion: `The man is pointing because he wants to ${maskStr}.`,
        options: ['tell the server who ordered the pancakes', 'accuse the other man of something'],
        useSound: false,
    },
    {
        title: 'Roller Coaster',
        label: 'Roller Coaster',
        url: 'https://www.youtube.com/watch?v=EpYYTRvKcZY',
        placeholderImg: rollerCoasterSrc,
        question: 'Is it possible to ride this roller coaster now?',
        maskedQuestion: `is it possible to ride this roller coaster now? currently ${maskStr}`,
        options: ['it is possible', 'it is not possible'],
        useSound: false,
    },
    {
        title: 'A Day in the life of Ski Patrol2',
        label: 'A Day in the life of Ski Patrol',
        url: 'https://www.youtube.com/watch?v=77WpRPpxWDM',
        placeholderImg: skiPatrolSrc,
        question: `Ski Patrollers need to wake up around ${maskStr} am in the morning`,
        maskedQuestion: `Ski Patrollers need to wake up around ${maskStr} am in the morning`,
        options: [
            "five o'clock",
            'five thirty',
            "six o'clock",
            'six thirty',
            "seven o'clock",
            'seven thirty',
            "eight o'clock",
            'eight thirty',
            "nine o'clock",
            'nine thirty',
            "ten o'clock",
            'ten thirty',
            "eleven o'clock",
            'eleven thirty',
            "noon o'clock",
        ],
        useSound: true,
    },
    {
        title: 'One Minute Film: Toast',
        label: 'One Minute Film: Toast',
        url: 'https://www.youtube.com/watch?v=xV9HnITo2C0',
        placeholderImg: toastSrc,
        question: 'what meal does the person want to eat?',
        maskedQuestion: `this video shows someone who is trying to eat ${maskStr}`,
        options: ['breakfast', 'lunch', 'dinner'],
        useSound: true,
    },
    {
        title: 'The Golden Idol',
        label: 'The Golden Idol',
        url: 'https://www.youtube.com/watch?v=0gU35Tgtlmg',
        placeholderImg: idolSrc,
        question: `The man is using the bag as a ${maskStr} for the golden idol`,
        maskedQuestion: `The man is using the bag as a ${maskStr} for the golden idol`,
        options: ['counterweight', 'container'],
        useSound: true,
    },
    {
        title: 'The Golden Idol 2',
        label: 'The Golden Idol 2',
        url: 'https://www.youtube.com/watch?v=0gU35Tgtlmg',
        placeholderImg: idolSrc,
        question: `Will he succeed in evading detection? He ${maskStr}`,
        maskedQuestion: `Will he succeed in evading detection? He ${maskStr} `,
        options: ['will succeed', 'will not succeed'],
        useSound: true,
    },
    {
        title: "I'm walking over here (contains profanity)",
        label: "I'm walking over here (contains profanity)",
        url: 'https://www.youtube.com/watch?v=_Z-tCU-sULA',
        placeholderImg: idolSrc,
        question: `Does the man on the left look like he belongs in this city? ${maskStr}`,
        maskedQuestion: `Does the man on the left look like he belongs in this city? ${maskStr} `,
        options: ['he does', "he doesn't"],
        useSound: true,
    },
    // {
    //     title: 'I\'m walking over here (contains profanity)',
    //     label: 'I\'m walking over here (contains profanity)',
    //     url: 'https://www.youtube.com/watch?v=_Z-tCU-sULA',
    //     placeholderImg: idolSrc,
    //     question: `Does the man on the left look like he belongs in this city? ${maskStr}`,
    //     maskedQuestion: `Does the man on the left look like he belongs in this city? ${maskStr} `,
    //     options: [
    //         'he does',
    //         'he doesn\'t',
    //     ],
    //     useSound: true,
    // },

    //    {
    //        title: 'Bagel Rivalry',
    //        label: 'Bagel Rivalry',
    //        url: 'https://www.youtube.com/watch?v=LF4Pm9KTGY0',
    //        placeholderImg: bagelsSrc,
    //        question: "There is a rivalry between these two cities, about which food?",
    //        maskedQuestion: `there is a rivalry between the cities of ${maskStr} and ${maskStr} about which city has more delicious ${maskStr}`,
    //        options: ['Bagels', 'Donuts', 'Pizza', 'Hamburgers', 'French fries', 'Tacos', 'Churros', 'Cake', 'Ice cream', 'Cookies',
    // 'Pie', 'New York', 'Los Angeles', 'Chicago', 'Houston', 'Philadelphia', 'Phoenix', 'San Antonio', 'San Diego', 'Dallas', 'San Jose', 'Austin', 'Indianapolis', 'Jacksonville', 'San Francisco', 'Columbus', 'Fort Worth', 'Charlotte', 'Detroit', 'El Paso', 'Memphis', 'Seattle', 'Denver', 'Washington', 'Boston', 'Nashville', 'Milwaukee', 'Louisville', 'Oklahoma City', 'Portland', 'Las Vegas', 'Tampa', 'Atlanta', 'Baltimore', ' Cincinnati', 'Sacramento', 'Kansas City', 'Montreal', 'Toronto', 'Calgary', 'Edmonton', 'Vancouver'],
    //        useSound: true,
    //    },
    // {
    //     title: 'Hollywood Foley Studio',
    //     label: 'Hollywood Foley Studio',
    //     url: 'https://www.youtube.com/watch?v=UO3N_PRIgX0',
    //     placeholderImg: foleySrc,
    //     question: 'What is the purpose of their studio?',
    //     maskedQuestion: `the purpose of their studio is ${maskStr}.`,
    //     options: ['breakfast', 'toast'],
    //     useSound: true,
    // },
    // {
    //     title: 'Cheese Onigiri',
    //     label: 'Cheese Onigiri',
    //     url: 'https://www.youtube.com/watch?v=KqnPeKD_X-o',
    //     placeholderImg: onigiriSrc,
    //     question: 'Is this recipe vegan?',
    //     maskedQuestion: `First, I'm going to ${maskStr} my iphone.`,
    //     options: ['open up', 'seal', 'close'],
    //     useSound: true,
    // },
    {
        title: 'Sideways Movie Scene (note: contains profanity)',
        label: 'Sideways Movie Scene (note: contains profanity)',
        url: 'https://www.youtube.com/watch?v=CkLUnqgpEuA',
        placeholderImg: sidewaysSrc,
        question: `will they end up drinking MERLOT later on?`,
        maskedQuestion: `will they end up drinking MERLOT later on? ${maskStr}.`,
        options: ['they will', "they won't"],
        useSound: true,
    },
    {
        title: "This Actor's Cartoon Game is strong",
        label: "This Actor's Cartoon Game is strong",
        url: 'https://www.youtube.com/watch?v=jpgnMeDjnO8',
        placeholderImg: voiceSrc,
        question: `Who is a voice actor -- the woman with the pink sweater, or the man with a black hoodie?`,
        maskedQuestion: `Who is a voice actor -- the woman with the pink sweater, or the man with a black hoodie? It's the ${maskStr}`,
        options: ['woman', 'man'],
        useSound: true,
    },
    {
        title: 'Moonlight - Beach Scene',
        label: 'Moonlight - Beach Scene',
        url: 'https://www.youtube.com/watch?v=z6yMItXePG8',
        placeholderImg: moonlightSrc,
        question: `Has the boy swam in the ocean before?`,
        maskedQuestion: `Has the boy swam in the ocean before? He ${maskStr}`,
        options: ["hasn't", 'has'],
        useSound: true,
    },
    {
        title: 'Road Trip',
        label: 'Road Trip',
        url: 'https://www.youtube.com/watch?v=clofw7v0tfo',
        placeholderImg: pchSrc,
        question: 'what kind of vacation is this?',
        maskedQuestion: `in this video, I am ${maskStr} in ${maskStr}`,
        options: [
            'going on a road trip',
            'hitchhiking',
            'going skiing',
            'going on a cruise',
            'staying at home',
            'visiting a theme park',
            'visiting a relative or friend',
            'going on a trip to a different country',
            'going camping',
            'going to a music festival',
            'going to a movie theater',
            'going to a restaurant',
            'going to a bar or club',
            'going for a walk',
            'going for a run',
            'lying in bed',
            'Alabama',
            'Alaska',
            'Arizona',
            'Arkansas',
            'California',
            'Colorado',
            'Connecticut',
            'Delaware',
            'Florida',
            'Georgia',
            'Hawaii',
            'Idaho',
            'Illinois',
            'Indiana',
            'Iowa',
            'Kansas',
            'Kentucky',
            'Louisiana',
            'Maine',
            'Maryland',
            'Massachusetts',
            'Michigan',
            'Minnesota',
            'Mississippi',
            'Missouri',
            'Montana',
            'Nebraska',
            'Nevada',
            'New Hampshire',
            'New Jersey',
            'New Mexico',
            'New York',
            'North Carolina',
            'North Dakota',
            'Ohio',
            'Oklahoma',
            'Oregon',
            'Pennsylvania',
            'Rhode Island',
            'South Carolina',
            'South Dakota',
            'Tennessee',
            'Texas',
            'Utah',
            'Vermont',
            'Virginia',
            'Washington',
            'West Virginia',
            'Wisconsin',
            'Wyoming',
        ],
        useSound: false,
    },
    {
        title: 'Cooking Show',
        label: 'Cooking Show',
        url: 'https://www.youtube.com/watch?v=m6kcANnAJSo',
        placeholderImg: cookingSrc,
        question: 'What is he cooking?',
        maskedQuestion: `I am cooking ${maskStr} today.`,
        options: [
            'rice',
            'pork meat',
            'cucumber',
            'curry',
            'curry vegetables',
            'corn',
            'potato soup',
            'tomatoes',
            'tortilla',
            'turkey',
            'vegetable',
            'vegetable pie',
            'coffee',
            'potatoes',
            'pudding',
            'salad',
            'salads',
            'salmon',
            'salsa',
            'salt',
            'sandwich',
            'sandwiches',
            'sauce',
            'sausage',
            'seafood',
        ],
        useSound: true,
    },
];

enum FormName {
    Title = 'title',
    Video = 'video',
    MaskedQuestion = 'maskedQuestion',
    Options = 'options',
    UseSound = 'useSound',
}

export const Home = () => {
    const history = useHistory();
    const location = useLocation();
    const [form] = Form.useForm<FormValue>();
    const [optionsCount, setOptionsCount] = useState<number>(0);
    const [isFetchingAnswer, setIsFetchingAnswer] = useState(false);
    const [answer, setAnswer] = useState<Answer | undefined>();
    const [error, setError] = useState<string | undefined>();
    const [reload, setReload] = useState<number>(0);
    const playerRef = React.createRef<ReactPlayer>();

    useEffect(() => {
        fetchAnswer();
    }, [location]);

    const fetchAnswer = () => {
        const query = Query.fromQueryString(location.search);

        if (query.isValid()) {
            updateFormValues(query);

            // reset answers and errors before we go fetch new results
            setError(undefined);
            setAnswer(undefined);
            setIsFetchingAnswer(true);

            // Note: validation is occurring on the backend, so errors will be thrown if there are any invalid inputs
            submit(query)
                .then((responseAnswer) => {
                    // When the API returns successfully update the answer
                    setError(undefined);
                    setAnswer(responseAnswer);
                    // update form based on response
                    const updatedQuery = new Query(
                        query.title,
                        query.video,
                        // using the returned masked statement in case they want to see/alter it
                        responseAnswer.response.maskedQuestion || query.maskedQuestion,
                        // using the returned options in case they want to see/alter them
                        responseAnswer.response.options || query.options,
                        query.useSound
                    );
                    updateFormValues(updatedQuery);
                })
                .catch((err) => {
                    // See if we can display as specific of an error as possible
                    let error;
                    if (err.response) {
                        // If the check below is true, the API returned
                        // error message that's likely helpful to display
                        if (err.response.data && err.response.data.error) {
                            error = err.response.data.error;
                        } else if (err.response.status === 503) {
                            error =
                                'Our system is a little overloaded, ' +
                                'please try again in a moment';
                        }
                    }

                    // Fallback to a general error message
                    if (!error) {
                        error =
                            'Sorry, the wine is corked. Let it breathe and try another glass later.';
                    }
                    setAnswer(undefined);
                    setError(error);
                })
                .finally(() => {
                    setIsFetchingAnswer(false);
                });
        }
    };

    const handleSubmit = (values: FormValue) => {
        // We add the query params to the URL, so that users can link to
        // our demo and share noteworthy cases, edge cases, etc.
        const query = new Query(
            values.title,
            values.video,
            values.maskedQuestion,
            values.options,
            values.useSound
        );
        // pushing this new URL will automatically trigger a new query (see the 'useEffect' function above)
        history.push(`/?${query.toQueryString()}`);
    };

    const handleOptionAdded = () => {
        const items = form.getFieldValue(FormName.Options);
        if (items) {
            setOptionsCount(items.length);
        }
    };

    const updateFormValues = (values: Partial<Query>) => {
        form.setFieldsValue(values);
        handleOptionAdded();
    };

    // hack to get initial values of checkbox set
    // let autoComputeMaskedQuestion = form.getFieldValue(FormName.AutoComputeMaskedQuestion);
    // if (undefined === autoComputeMaskedQuestion) {
    //     autoComputeMaskedQuestion = true;
    // }
    // let autoComputeOptions = form.getFieldValue(FormName.AutoComputeOptions);
    // if (undefined === autoComputeOptions) {
    //     autoComputeOptions = true;
    // }

    return (
        <div>
            <MaxWidthParagraph>
                <textStyles.Big>
                    MERLOT Reserve is a model for predicting missing dialogue in a video. Given a
                    dialogue, with some phrases replaced by the word {maskStr}, it fills in the
                    missing phrases from a list of multiple-choice options. MERLOT Reserve makes
                    this prediction by jointly reasoning over the video frames, the subtitles, and
                    the audio.
                </textStyles.Big>
            </MaxWidthParagraph>
            <MaxWidthParagraph>
                <textStyles.Big>
                    MERLOT Reserve can be used to perform many simple video understanding tasks,
                    without needing to use any human-labeled data. For your first pour, choose a
                    video below. You can then write out a dialogue and a list of possible answers.
                </textStyles.Big>
            </MaxWidthParagraph>
            <MaxWidthParagraph>
                <textStyles.Big>
                    <strong>
                        To learn more:{' '}
                        <a
                            href="https://rowanzellers.com/merlotreserve/"
                            target="_blank"
                            rel="noreferrer">
                            <PicRightOutlined /> article
                        </a>
                        {' | '}
                        <a
                            href="https://www.semanticscholar.org/paper/MERLOT-Reserve%3A-Neural-Script-Knowledge-through-and-Zellers-Lu/e056c2f0298c653f9a6fabf5d10b0aa5c087770c"
                            target="_blank"
                            rel="noreferrer">
                            <FileTextOutlined /> paper
                        </a>
                        {' | '}
                        <a href="https://rowanzellers.com/merlotreserve/#model">
                            <ProfileOutlined /> model
                        </a>
                        {' | '}
                        <a href="https://github.com/rowanz/merlot_reserve/">
                            <GithubOutlined /> code
                        </a>
                    </strong>
                </textStyles.Big>
            </MaxWidthParagraph>
            <MaxWidthForm
                layout="vertical"
                form={form}
                scrollToFirstError
                onFinish={(values) => handleSubmit(values as FormValue)}
                onChange={() => {
                    // hack, yuck.  The form is not reloading on update.
                    setReload(reload + 1);
                }}>
                <Form.Item label="Video" name={FormName.Title} rules={[{ required: true }]}>
                    <Select
                        placeholder="Choose a video"
                        optionLabelProp="label"
                        onChange={(v) => {
                            const example = examples.find((ex) => ex.title === v);
                            if (example) {
                                updateFormValues(
                                    new Query(
                                        example.title,
                                        example.url,
                                        example.maskedQuestion,
                                        example.options.filter((option) => option !== ''),
                                        example.useSound
                                    )
                                );
                                // hack, yuck.  The form is not reloading on update.
                                setReload(reload + 1);
                            }
                        }}>
                        {examples.map((ex) => {
                            return (
                                <Select.Option key={ex.title} value={ex.title} label={ex.label}>
                                    <ExampleContainer>
                                        <ExampleImg src={ex.placeholderImg} />
                                        <ExampleTitle>{ex.label}</ExampleTitle>
                                        <ExampleQuestion>{ex.question}</ExampleQuestion>
                                    </ExampleContainer>
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                {reload >= 0 && form.getFieldValue(FormName.Video) ? (
                    <Form.Item name={FormName.Video}>
                        <ReactPlayer
                            ref={playerRef}
                            height={200}
                            width={600}
                            url={form.getFieldValue(FormName.Video)}
                            controls={true}
                        />
                    </Form.Item>
                ) : null}

                <Form.Item
                    label="Masked Dialogue"
                    name={FormName.MaskedQuestion}
                    rules={[{ required: true }]}
                    extra={`Must include one or more '${maskStr}' tokens that will be replaced with predicted the answers.`}>
                    <Input.TextArea
                        disabled={!form.getFieldValue(FormName.Video)}
                        autoSize={{ minRows: 4, maxRows: 6 }}
                        placeholder="Enter a masked dialogue"
                    />
                </Form.Item>

                <Form.Item
                    label={
                        'Possible Answers' +
                        (optionsCount > MaxOptionsToReturn
                            ? ` (${optionsCount} selected, Merlot will only use the first ${MaxOptionsToReturn})`
                            : '')
                    }
                    name={FormName.Options}
                    rules={[{ required: true }]}
                    extra={`List of possible predictions to replace '${maskStr}' in the statement.`}>
                    <ScrollingSelect
                        disabled={!form.getFieldValue(FormName.Video)}
                        mode="tags"
                        allowClear={true}
                        onChange={handleOptionAdded}
                        placeholder="Enter possible answers"></ScrollingSelect>
                </Form.Item>

                <Form.Item name={FormName.UseSound} valuePropName="checked">
                    <Checkbox disabled={!form.getFieldValue(FormName.Video)}>Use Sound?</Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isFetchingAnswer}
                        disabled={
                            !new Query(
                                form.getFieldValue(FormName.Title),
                                form.getFieldValue(FormName.Video),
                                form.getFieldValue(FormName.MaskedQuestion),
                                form.getFieldValue(FormName.Options),
                                form.getFieldValue(FormName.UseSound)
                            ).isValid()
                        }>
                        Submit
                    </Button>
                </Form.Item>
                {error && !answer ? (
                    <Alert
                        type="error"
                        message={error || 'Something went wrong. Please try again.'}
                    />
                ) : null}
            </MaxWidthForm>
            {!error && answer ? <AnswerPredictions data={answer} maskStr={maskStr} /> : null}
            <MosaicFooter />
            <Button icon={<StarOutlined />} href="https://allenai.org/demos">
                Try other AI demos from AI2
            </Button>
        </div>
    );
};

const MaxWidthParagraph = styled.p`
    max-width: 800px;
`;

const MaxWidthForm = styled(Form)`
    max-width: 600px;
    margin-bottom: ${({ theme }) => theme.spacing.xl3};
`;

const ExampleContainer = styled.div`
    display: grid;
    grid-template:
        'image title' 1fr
        'image question' 1fr / auto 1fr;
    gap: ${({ theme }) => `0 ${theme.spacing.md.px}`};
`;

const ExampleImg = styled.img`
    grid-area: image;
    width: ${({ theme }) => theme.spacing.xl5};
`;

const ExampleTitle = styled.div`
    grid-area: title;
    align-self: end;
    font-weight: bold;
`;

const ExampleQuestion = styled.div`
    grid-area: question;
    align-self: start;
`;

const ScrollingSelect = styled(Select)`
    max-height: 200px;
    overflow-y: scroll;
`;

const AnswerPredictions = styled(Predictions)`
    && {
        margin-top: ${({ theme }) => theme.spacing.xl2};
        max-width: 800px;
    }
`;
