import React from 'react';
import styled from 'styled-components';
import { BrowserRouter, Route } from 'react-router-dom';
import { Header, Content, Footer, Layout } from '@allenai/varnish';
import { FacebookFilled, TwitterSquareFilled, LinkedinFilled } from '@ant-design/icons';

import { Home } from './pages/Home';
import { AppRoute } from './AppRoute';

import headerLogo from './images/merlotreserve.svg';

const ROUTES: AppRoute[] = [
    {
        path: '/',
        label: 'Home',
        component: Home,
    },
];

export const App = () => {
    return (
        <BrowserRouter>
            <Route path="/">
                <Layout bgcolor="white">
                    <Header>
                        <Header.Columns columns="auto 1fr auto">
                            <Header.Logo label={<Header.AppName>Merlot Reserve</Header.AppName>}>
                                <Logo />
                            </Header.Logo>
                            <span />
                            <ShareArea>
                                <a
                                    href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmerlot.apps.allenai.org"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <FacebookFilled alt="Share on Facebook" />
                                </a>
                                <a
                                    href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fmerlot.apps.allenai.org&text=Demo%20for%20MERLOT%20Reserve%2C%20a%20model%20that%20learns%20to%20answer%20questions%20about%20videos%20--%20through%20language%2C%20vision%2C%20and%20sound."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <TwitterSquareFilled alt="Share on Twitter" />
                                </a>
                                <a
                                    href="http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fmerlot.apps.allenai.org&title=Merlot%20Reserve&summary=Demo%20for%20MERLOT%20Reserve%2C%20a%20model%20that%20learns%20to%20answer%20questions%20about%20videos%20--%20through%20language%2C%20vision%2C%20and%20sound."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <LinkedinFilled alt="Share on Linkedin" />
                                </a>
                            </ShareArea>
                        </Header.Columns>
                    </Header>
                    <Content main>
                        {ROUTES.map(({ path, component }) => (
                            <Route key={path} path={path} exact component={component} />
                        ))}
                    </Content>
                    <Footer />
                </Layout>
            </Route>
        </BrowserRouter>
    );
};

const Logo = styled.img.attrs({
    src: headerLogo,
    alt: 'Merlot Reserve',
    height: '53px',
})`
    margin-right: ${({ theme }) => theme.spacing.sm};
`;

const ShareArea = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: ${({ theme }) => theme.spacing.xs};
    align-items: center;
    justify-self: end;
    a {
        font-size: ${({ theme }) => theme.typography.h2.fontSize};
        color: ${({ theme }) => theme.color.N6};
    }
`;
